import React, { useEffect } from 'react'
import Loader from '../../../components/Loader'

const saveAndClose = async (code) => {
  console.log('Closing popup window in a second')
  setTimeout(() => {
    window.close(code)
  }, 1000)
}

const StressInsightsConnectCalendarDone = () => {
  console.log('Done popup window v x.2')
  const query = new URLSearchParams(window.location.search)
  const code = query.get('code')
  console.log(code)
  useEffect(() => {
    if (code) {
      saveAndClose(code)
    }
  }, [code])

  return (<>
    <Loader></Loader>
  </>)
}

export default StressInsightsConnectCalendarDone
