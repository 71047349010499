import React, { useEffect, useState } from 'react'

const CalendarEvent = ({ event, stressDayAverage }) => {
  const [fullDay, setFullDay] = useState(false)

  useEffect(() => {
    setFullDay(event.originalEvent.start.date && event.originalEvent.end.date)
  }, [event])

  const getTime = time => {
    return (new Date(time)).toISOString().substring(11, 16)
  }

  return (
    <div className={`calendar-event ${fullDay ? 'full-day' : ''}`} style={{ backgroundColor: event.backgroundColor }}>
      {fullDay &&
        <p>Full Day</p>
      }
      {!fullDay &&
        <p>{getTime(event.start)} - {getTime(event.end)}</p>
      }
      <p>
        <strong>{event.title}</strong>
      </p>
      <p>Stress: <strong>{fullDay ? stressDayAverage : (event.stressAverage >= 1 ? event.stressAverage : 'No data')}</strong></p>
    </div>
  )
}

export default CalendarEvent
