import React from 'react'
import './step-counter.scss'

const StepCounter = ({ steps, step }) => {
  return (<div className="step-counter">
    {[...Array(steps)].map((x, i) => {
      const currentStep = i + 1
      return (
        <span key={currentStep}>
        {currentStep < step &&
          <>
            <div className="step step-done" key={x}>{currentStep}</div>
            <div className="step-spacer step-done-spacer"></div>
          </>
        }
        {currentStep === step &&
          <div className="step step-current" key={x}>{currentStep}</div>
        }
        {currentStep > step &&
          <>
            <div className="step-spacer step-next-spacer"></div>
            <div className="step step-next" key={x}>{currentStep}</div>
          </>
        }
        </span>
      )
    }
    )}
  </div>)
}

export default StepCounter
