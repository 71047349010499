import mixpanel from 'mixpanel-browser'
import config from '../config/mixpanel'
const { token, debug } = config
const getMixpanel = () => {
  mixpanel.init(token, { debug })
  return mixpanel
}

export {
  getMixpanel
}
