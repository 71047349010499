import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { ModalBody, ModalHeader, ModalFooter, Modal } from 'reactstrap'
import { garminOauthDisconnect } from '../../../api/functions'
import Button from '../../../components/Button'
import Loader from '../../../components/Loader'

const ExitModal = ({ exitModal, setExitModal, patientId }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const disconnect = async () => {
    setLoading(true)
    await garminOauthDisconnect({ patientId })
    localStorage.setItem('patientId', false)
    navigate('/')
  }

  const logout = () => {
    localStorage.setItem('patientId', false)
    navigate('/')
  }

  return (<Modal isOpen={exitModal} toggle={() => setExitModal(false)}>
    <ModalHeader toggle={() => setExitModal(false)}>Leave?</ModalHeader>
    <ModalBody>
      Are you sure you want to logout?
    </ModalBody>
      {loading &&
        <ModalFooter>
          <Loader></Loader>
        </ModalFooter>
      }
      {!loading &&
        <ModalFooter>
          <Button btnStyle="simple" toggle={() => setExitModal(false)} text="Cancel">
          </Button>
          <Button onClick={() => disconnect()} text="Disconnect Garmin" style={{ backgroundColor: 'black' }}>
          </Button>
          <Button onClick={() => logout()} text="Logout" style={{ backgroundColor: 'red' }}>
          </Button>
        </ModalFooter>
      }
  </Modal>)
}

export default ExitModal
