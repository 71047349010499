
import _ from 'lodash'

const normalizeDate = (originalDate, day) => {
  const today = new Date(day.start)
  const date = new Date(originalDate)
  date.setFullYear(today.getFullYear())
  date.setMonth(today.getMonth())
  date.setDate(today.getDate())

  return date.toISOString()
}

const setBackgroundColor = (stressAverage) => {
  const colors = [
    '#CEDCFF',
    '#CEFFD6',
    '#FFF6A2',
    '#FF7E7E'
  ]

  if (stressAverage < 1) {
    return '#CBCBCB'
  }

  if (stressAverage < 25) {
    return colors[0]
  }

  if (stressAverage < 50) {
    return colors[1]
  }

  if (stressAverage < 75) {
    return colors[2]
  }

  return colors[3]
}

const prepareComputedEvents = (data, labels, stressData, bodyBatteryData, day) => {
  const eventData = []
  data.forEach(event => {
    const eventDataPoints = []
    if (event.start.date) {
      const startEvent = new Date(event.start.date)
      const dayStart = new Date(day.start)

      if (startEvent > dayStart) {
        return
      }
    }

    const start = normalizeDate(event.start.dateTime || event.start.date, day)
    const end = normalizeDate(event.end.dateTime || event.end.date, day)
    let stressSum = 0
    let timeEpochs = 0

    const startIndex = _.findIndex(labels, l => l === start)
    const endIndex = _.findIndex(labels, l => l === end)

    if (startIndex !== -1 && endIndex !== -1) {
      for (let i = startIndex + 1; i < endIndex; i++) {
        if (stressData[i] > 0) {
          stressSum += stressData[i]
          timeEpochs++
        }
      }

      eventDataPoints[startIndex] = 0
      for (let i = startIndex + 1; i < endIndex; i++) {
        eventDataPoints[i] = 100
      }
      eventDataPoints[endIndex] = 0
    }

    if (timeEpochs < 1) {
      timeEpochs = 1
    }

    const stressStart = stressData[startIndex]
    const stressEnd = stressData[endIndex]
    const stressAverage = Math.floor(stressSum / timeEpochs)
    const backgroundColor = setBackgroundColor(stressAverage)

    eventData.push({
      start,
      end,
      startIndex,
      endIndex,
      stressStart,
      stressEnd,
      stressSum,
      timeEpochs,
      labelsLength: labels.length + 0,
      stressAverage,
      bodyBatteryStart: bodyBatteryData[startIndex],
      bodyBatteryEnd: bodyBatteryData[endIndex],
      bodyBatteryConsumed: bodyBatteryData[startIndex] - bodyBatteryData[endIndex],
      title: event.summary,
      originalEvent: event,
      backgroundColor,
      eventDataPoints
    })
  })

  return eventData
}

export {
  prepareComputedEvents
}
