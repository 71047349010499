import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter } from 'react-router-dom'
import Routing from './core/routes'
import store from './core/store'
import { app } from './api/firebase'
import 'bootstrap/dist/css/bootstrap.css'
import 'react-toastify/dist/ReactToastify.css'
import './styles/base/index.scss'

ReactDOM.render(
  <Provider store={store}>
    <ToastContainer autoClose={2000} />
    <BrowserRouter>
      <Routing app={app} />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)
