import { createAction, createSlice } from '@reduxjs/toolkit'
import constants from '../constants'

const initialState = {
  user: null,
  metadata: {}
}

export const user = createSlice({
  name: 'USER',
  initialState,
  reducers: {
    setUser: (state, action) => ({
      ...state,
      user: action.payload
    }),
    setUserMetadata: (state, action) => ({
      ...state,
      metadata: action.payload
    })
  }
})

// for redux (sync)
export const { actions } = user

// for saga (async)
export const signInUser = createAction(constants.SIGN_IN_REQUEST)
export const signOutUser = createAction(constants.SIGN_OUT_REQUEST)
export const checkUser = createAction(constants.CHECK_USER_REQUEST)

export default user.reducer
