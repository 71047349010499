import { httpsCallable } from 'firebase/functions'
import { functions } from './firebase'

const garminOauthAccessToken = httpsCallable(functions, 'garminOauthAccessToken')
const garminOauthDisconnect = httpsCallable(functions, 'garminOauthDisconnect')
const garminOauthRequestToken = httpsCallable(functions, 'garminOauthRequestToken')
const garminDataBodyBattery = httpsCallable(functions, 'garminDataBodyBattery')
const garminDataSleep = httpsCallable(functions, 'garminDataSleep')
const garminDataStress = httpsCallable(functions, 'garminDataStress')
const garminPatientHasReceivedData = httpsCallable(functions, 'garminPatientHasReceivedData')

const googleCalendarGetEvents = httpsCallable(functions, 'googleCalendarGetEvents')
const googleOauthSaveTokens = httpsCallable(functions, 'googleOauthSaveTokens')
const googleOauthRequestRedirectUrl = httpsCallable(functions, 'googleOauthRequestRedirectUrl')
const userCountEvent = httpsCallable(functions, 'userCountEvent')

export {
  garminOauthAccessToken,
  garminOauthDisconnect,
  garminOauthRequestToken,
  garminDataBodyBattery,
  garminDataSleep,
  garminDataStress,
  garminPatientHasReceivedData,
  googleCalendarGetEvents,
  googleOauthSaveTokens,
  googleOauthRequestRedirectUrl,
  userCountEvent
}
