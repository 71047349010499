import React, { useState } from 'react'
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import './index.scss'

const url = 'https://si.earkick.com/'

const ShareWithFriends = () => {
  const [shareModal, setShareModal] = useState(false)
  const [copied, setCopied] = useState(false)

  async function copyTextToClipboard (text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  const handleCopyClick = (text) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(text)
      .then(() => {
        // If successful, update the isCopied state value
        setCopied(true)
        setTimeout(() => {
          setCopied(false)
        }, 5000)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className="share-with-friends">
      <Button onClick={() => setShareModal(true)}>Share with friends</Button>
      <Modal isOpen={shareModal} toggle={() => setShareModal(false)}>
        <ModalHeader toggle={() => setShareModal(false)}>Share with friends</ModalHeader>
        <ModalBody>
          <div>
            Use this url to spread a word about Stress Insights:
          </div>
          <br />
          <div>
            <Input value={url} onChange={() => {}} />
          </div>
        </ModalBody>
          <ModalFooter>
            {!copied &&
              <Button onClick={() => handleCopyClick(url)}>Copy Share Url</Button>
            }
            {copied &&
              <Button disabled={true}>Copied!</Button>
            }
            <Button btnStyle="simple" onClick={() => setShareModal(false)}>Close</Button>
          </ModalFooter>
      </Modal>
    </div>
  )
}

export default ShareWithFriends
