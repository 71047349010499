import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'

import productionConfig from './production_config.json'
import stagingConfig from './staging_config.json'

const config = process.env.REACT_APP_TYPE === 'staging' ? stagingConfig : productionConfig

const app = initializeApp(config)
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage()
const functions = getFunctions()

export {
  app,
  auth,
  db,
  functions,
  storage
}
