import React, { memo, useEffect, Suspense } from 'react'
import {
  Routes,
  Route
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import GuestLayout from '../../layouts/GuestLayout'

// import Garmin from '../../containers/garmin'
import GarminConnect from '../../containers/stress-insights/garmin/connect'
import StressInsightsGuestLayout from '../../containers/stress-insights/layout/guest'
import StressInsightsConnectCalendar from '../../containers/stress-insights/calendar/connect'
import StressInsightsConnectCalendarDone from '../../containers/stress-insights/calendar/done'
import GarminDone from '../../containers/stress-insights/garmin/done'
import GarminData from '../../containers/stress-insights/chart/data'

import Google from '../../containers/google/index'

const Routing = memo(() => {
  const userMetadata = useSelector((state) => state.userReducers.metadata)
  useEffect(() => {

  }, [userMetadata])

  return (
    <Suspense className='main-container' fallback={<div>Loading...</div>}>
      <Routes>
        <Route path='/*' element={<GuestLayout />}>
          <Route path="*" element={<p>404</p>} />
        </Route>

        <Route element={<StressInsightsGuestLayout />}>
          <Route path='' element={<StressInsightsConnectCalendar />}></Route>
          <Route path='/garmin' element={<GarminConnect />}></Route>
        </Route>
        <Route path='/garmin/done' element={<GarminDone />}></Route>
        <Route path='/calendar-connect/success' element={<StressInsightsConnectCalendarDone />}></Route>
        <Route path='/:day' element={<GarminData />}></Route>

        <Route path='/google/:patientId' element={<Google />}></Route>
      </Routes>
    </Suspense>
  )
})

Routing.displayName = 'Routing'

export default Routing
