import React, { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { useSelector } from 'react-redux'
import {
  garminDataBodyBattery,
  garminDataStress,
  garminDataSleep,
  googleCalendarGetEvents,
  userCountEvent
} from '../../../api/functions'

import {
  prepareComputedEvents
} from './computations'

import ChartArea from './chart-area'
import ExitModal from './exit-modal'

import Loader from '../../../components/Loader'
import { addDays, yesterday } from '../../../utils/date'
import './data.scss'
// import { patientsSubscribeOne } from '../../api/patients/patients'
import logo from '../../../images/logo-panda-text.svg'
import CalendarEvent from './calendar-event'
import { getMixpanel } from '../../../api/mixpanel'
import ShareWithFriends from './share-with-friends'

const getNumberOfDays = (start, end) => {
  const date1 = new Date(start)
  const date2 = new Date(end)
  const oneDay = 1000 * 60 * 60 * 24
  const diffInTime = date2.getTime() - date1.getTime()
  return Math.round(diffInTime / oneDay)
}

const mixpanel = getMixpanel()
const trackDaySeen = async ({ date, patientId }) => {
  const eventName = 'See Calendar Page At Date B01'
  const response = await userCountEvent({
    patientId,
    eventName,
    app: 'stress-insights',
    eventOption: date
  })

  mixpanel.track(eventName, {
    'Total See Calendar Page At Date': response.data.count,
    'Days back': getNumberOfDays(date, new Date()),
    Date: date
  })
  mixpanel.people.increment(eventName)
}

const getAverageOfPositives = data => {
  return Math.ceil(_.mean(_.filter(data, o => o > -1)))
}
const StressInsightsChart = () => {
  const datePicker = useRef()

  const [loading, setLoading] = useState(true)
  const [labels, setLabels] = useState([])
  const [stressData, setStressData] = useState([])
  const [stressDayAverage, setStressAverage] = useState(0)
  const [bodyBatteryData, setBodyBatteryData] = useState([])
  const patientId = useSelector(state => state.stressInsights.user) || localStorage.getItem('patientId')
  const [date, setDate] = useState((new Date(yesterday()).toISOString().substring(0, 10)))
  const [day, setDay] = useState(false)
  const [calendarData, setCalendarData] = useState([])
  const [calendarDataKey, setCalendarDataKey] = useState(uuid())
  const [exitModal, setExitModal] = useState(false)

  const [garminDataStressLoaded, setGarminDataStressLoaded] = useState(false)
  const [garminDataBodyBatteryLoaded, setGarminDataBodyBatteryLoaded] = useState(false)
  const [calendarDataLoaded, setCalendarDataLoaded] = useState(false)
  // const [patient, setPatient] = useState(false)

  useEffect(() => {
    const run = async () => {
      const { data } = await garminDataSleep({ patientId, date })

      setDay(data.day)
    }

    if (!day) {
      run()
    }
  }, [patientId, date])

  useEffect(() => {
    const run = async () => {
      const { data } = await googleCalendarGetEvents({ patientId, from: day.start, to: day.end })

      if (data.error) {
        console.log('Google caledar returned error')
        console.log(data.error)
        return
      }

      const events = prepareComputedEvents(data.events || [], labels, stressData, bodyBatteryData, day)

      setCalendarData(_.sortBy(events, e => {
        return (new Date(e.start)).getTime()
      }))
      setCalendarDataLoaded(true)
    }

    if (day && garminDataStressLoaded && garminDataBodyBatteryLoaded) {
      run()
    }
  }, [
    patientId,
    day,
    labels,
    calendarDataKey,
    stressData,
    bodyBatteryData,
    garminDataStressLoaded,
    garminDataBodyBatteryLoaded
  ])

  /*
  useEffect(() => {
    return patientsSubscribeOne(patientId, setPatient)
  }, [patientId])
  */

  useEffect(() => {
    const run = async () => {
      let { data } = await garminDataStress({
        patientId,
        start: day.start,
        end: day.end
      })
      data = _.omit(data, ['success', 'functionVersion'])

      trackDaySeen({ patientId, date })

      const dataValues = _.map(data, (key, value) => key)

      setStressData(dataValues)
      setStressAverage(getAverageOfPositives(dataValues))
      setLoading(false)
      setGarminDataStressLoaded(true)
    }

    if (day && stressData.length < 1) {
      run()
    }
  }, [patientId, date, day])

  useEffect(() => {
    const run = async () => {
      let { data } = await garminDataBodyBattery({
        patientId,
        start: day.start,
        end: day.end
      })

      data = _.omit(data, ['success', 'functionVersion'])

      const dataValues = _.map(data, (key, value) => key)
      const dataKeys = _.map(data, (key, value) => value)

      setBodyBatteryData(dataValues)
      setLabels(dataKeys)
      setGarminDataBodyBatteryLoaded(true)
    }

    if (day && stressData.length < 1) {
      run()
    }
  }, [patientId, date, day])

  const changeDate = async (newDate) => {
    setLoading(true)
    setDay(false)

    setGarminDataBodyBatteryLoaded(false)
    setGarminDataStressLoaded(false)
    setCalendarDataLoaded(false)
    setCalendarDataKey(uuid())
    setStressData([])
    setBodyBatteryData([])
    setCalendarData([])
    setDate(newDate)
  }

  return (<div className="page">
    <div className="sidebar">
      <div className="sidebar-container">
        <img src={logo} alt='logo' className='logo' />
        <div className="calendar">
          <div className="left-arrow" onClick={() => changeDate(addDays(new Date(date), -1).toISOString().substring(0, 10))}></div>
          <div className="calendar-element">
            <input
              type="date"
              ref={datePicker}
              onChange={e => changeDate(e.target.value)}
              value={date}
              onFocus={(e) => datePicker.current.showPicker()}
            ></input>
          </div>
          <div className="right-arrow" onClick={() => changeDate(addDays(new Date(date), 1).toISOString().substring(0, 10))}></div>
        </div>

        <div className="calendar-events">
          {calendarDataLoaded > 0 &&
            <>
              {calendarData.length > 0 && calendarData.map((event, index) => {
                return (<CalendarEvent key={index} event={event} stressDayAverage={stressDayAverage}></CalendarEvent>)
              })}
              {calendarData.length === 0 &&
                <center>No calendar events at this time</center>
              }
            </>
          }
          {!calendarDataLoaded > 0 &&
            <Loader></Loader>
          }
        </div>
      </div>
    </div>
    <div className="center-column">
      <ShareWithFriends></ShareWithFriends>
      <div className="exit-button" onClick={() => setExitModal(true)}></div>
      <h1>Stress Insights</h1>
      {loading &&
        <Loader></Loader>
      }
      {!loading &&
        <div>
          {stressData.length === 0 && bodyBatteryData.length === 0 &&
            <p>No data for this date :(</p>
          }
          <ChartArea
            labels={labels}
            bodyBatteryData={bodyBatteryData}
            stressData={stressData}
            calendarData={calendarData}
          ></ChartArea>

        </div>
      }
      <p className="disclaimer">All dates are in UTC. UTC time now: {new Date().toLocaleString()}</p>
    </div>
    <ExitModal
      exitModal={exitModal}
      setExitModal={setExitModal}
      patientId={patientId}
    ></ExitModal>

  </div>)
}

export default StressInsightsChart
