import rootReducers from './root'
import stressInsights from './stress-insights'
import userReducers from './user'

const reducers = {
  rootReducers,
  stressInsights,
  userReducers
}

export default reducers
