import { put, takeLatest } from 'redux-saga/effects'
import actions from '../actions'
import constants from '../constants'
import { checkUserAuth, signInUser, signOutUser } from '../../api/auth'
import { userGet } from '../../api/users'

const {
  showError, setUser, setUserMetadata, setIsLoading, setComponentIsLoading
} = actions

function * checkUser () {
  try {
    yield put(setIsLoading(true))
    const user = yield checkUserAuth()
    yield put(setUser(user))
    const userMetadata = yield userGet(user.email)
    yield put(setUserMetadata(userMetadata.metadata))
  } catch (error) {
    yield put(showError(error))
  } finally {
    yield put(setIsLoading(false))
  }
}

function * signOut () {
  try {
    yield put(setIsLoading(true))
    yield signOutUser()
    yield put(setUser(false))
    yield put(setUserMetadata(false))
  } catch (error) {
    yield put(showError(error))
  } finally {
    yield put(setIsLoading(false))
  }
}

function * signIn (action) {
  const {
    payload: {
      email, password
    }
  } = action
  try {
    yield put(setComponentIsLoading(true))
    const user = yield signInUser(email, password)
    yield put(setUser(user))
    const userMetadata = yield userGet(email)
    yield put(setUserMetadata(userMetadata.metadata))
  } catch (error) {
    switch (error.code) {
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        yield put(showError('Wrong email or password'))
        break

      default:
        yield put(showError('Too many requests. Please try again later.'))
        break
    }
  } finally {
    yield put(setComponentIsLoading(false))
  }
}

export default function * mainForm () {
  yield takeLatest(constants.CHECK_USER_REQUEST, checkUser)
  yield takeLatest(constants.SIGN_OUT_REQUEST, signOut)
  yield takeLatest(constants.SIGN_IN_REQUEST, signIn)
}
