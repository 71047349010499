import React from 'react'
import { Outlet } from 'react-router'
import './guest.scss'
import logo from '../../../images/logo-panda-text.svg'

const StressInsightsGuestLayout = () => {
  return (
    <div className='guest-layout'>
      <div className='login'>
        <div className='login__container'>
        <img src={logo} alt='logo' className='logo' />
        <h1>Stress Insights</h1>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

StressInsightsGuestLayout.displayName = 'StressInsightsGuestLayout'

export default StressInsightsGuestLayout
