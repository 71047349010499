import React, { useEffect, useState } from 'react'
import OauthPopup from './oauth-popup'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { Button } from 'reactstrap'
import {
  googleOauthRequestRedirectUrl,
  googleOauthSaveTokens,
  userCountEvent
} from '../../../api/functions'
import { getMixpanel } from '../../../api/mixpanel'
import Loader from '../../../components/Loader'
import StepCounter from '../../../components/step-counter'
import { setUser } from '../../../core/reducers/stress-insights'
import '../../LoginPage/index.scss'
import './connect.scss'

const mixpanel = getMixpanel()

const StressInisightsHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [requestData, setRequestData] = useState(false)
  const patientId = localStorage.getItem('patientId')

  const moveForward = (patientId, eventName) => {
    mixpanel.identify(patientId)

    mixpanel.people.increment(eventName)
    navigate('/garmin')
  }

  const onCode = async (code) => {
    setLoading(true)
    const { data } = await googleOauthSaveTokens({ code, host: window.location.origin })
    const { patientId } = data

    if (!patientId) {
      return alert('Something went wrong :(')
    }

    localStorage.setItem('patientId', patientId)

    await dispatch(setUser(patientId))

    const eventName = 'Authenticate A01'
    const response = await userCountEvent({
      patientId: data.patientId,
      eventName,
      app: 'stress-insights'
    })

    mixpanel.track(eventName, {
      'Total Authentications': response.data.count,
      'Coming from': 'Gmail'
    })

    moveForward(patientId, eventName)
  }

  useEffect(() => {
    if (patientId && patientId !== 'false') {
      dispatch(setUser(patientId))
      moveForward(patientId, 'Come back')
    }
  }, [patientId])

  useEffect(() => {
    const run = async () => {
      const { data } = await googleOauthRequestRedirectUrl({ host: window.location.origin })

      setRequestData({
        ...data
      })
      setLoading(false)
    }

    if (!requestData) {
      run()
    }
  }, [loading])

  return (<div className="google-calendar-connect-container">
    {!loading &&
      <OauthPopup
        url={requestData.url}
        onCode={onCode}
        onClose={() => {}}
      >
        <div className="google-calendar-connect">
          <Button className="button">Connect with your Google Calendar</Button>
        </div>
      </OauthPopup>
    }
    {loading &&
      <Loader></Loader>
    }
    <StepCounter
      steps={2}
      step={1}
    ></StepCounter>
  </div>)
}

export default StressInisightsHome
