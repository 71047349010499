import React, { useEffect } from 'react'
import { garminOauthUnsignedTokensUpdate } from '../../../api/garmin-connect/oauth'
import Loader from '../../../components/Loader'

const saveAndClose = async (oauthToken, verifier) => {
  await garminOauthUnsignedTokensUpdate(oauthToken, {
    createdAt: new Date(),
    updatedAt: new Date(),
    verifier
  })

  window.close()
}

const GarminDone = () => {
  const query = new URLSearchParams(window.location.search)
  const oauthToken = query.get('oauth_token')
  const verifier = query.get('oauth_verifier')

  useEffect(() => {
    if (oauthToken) {
      saveAndClose(oauthToken, verifier)
    }
  }, [oauthToken])

  return (<>
    <Loader></Loader>
  </>)
}

export default GarminDone
