
import React, { useRef } from 'react'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  registerables
} from 'chart.js'
import {
  Chart
/*
  getDatasetAtEvent,
  getElementAtEvent,
  getElementsAtEvent,
*/
} from 'react-chartjs-2'
import _ from 'lodash'

ChartJS.register(
  ...registerables,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
)

const options = {
  scales: {
    y: {
      beginAtZero: true
    },
    x: {
      ticks: {
        // For a category axis, the val is the index so the lookup via getLabelForValue is needed
        callback: function (val, index) {
          const data = this.getLabelForValue(val)
          const time = data.substring(14, 19)
          if (time === '00:00' || time === '30:00') {
            return data.substring(11, 16)
          }
          return null
        }
      }
    }
  }
}

const ChartArea = ({ labels, bodyBatteryData, stressData, calendarData }) => {
  const calendarEvents = []
  _.each(calendarData, event => {
    calendarEvents.push({
      type: 'line',
      label: event.title,
      borderColor: event.backgroundColor,
      backgroundColor: event.backgroundColor,
      pointRadius: 0,
      fill: true,
      data: event.eventDataPoints
    })
  })

  const chartRef = useRef(null)
  return (
    <Chart
      ref={chartRef}
      type='bar'
      // onClick={onClick}
      options={options}
      data={{
        labels,
        datasets: [
          {
            type: 'line',
            label: 'Body Battery',
            borderColor: 'blue',
            pointRadius: 0,
            borderWidth: 3,
            fill: false,
            data: bodyBatteryData
          },
          {
            type: 'bar',
            label: 'Stress Level',
            backgroundColor: (context) => {
              const index = context.dataIndex
              const value = context.dataset.data[index]
              if (value > 80) {
                return 'red'
              }
              if (value > 50) {
                return 'orange'
              }
              if (value > 25) {
                return 'green'
              }

              return 'blue'
            },
            borderWidth: 1,
            data: stressData
          },
          ...calendarEvents
        ]
      }}
      />
  )
}

export default ChartArea
