import React, { useEffect, useRef, useState } from 'react'
import Button from '../../../components/Button'
import Loader from '../../../components/Loader'

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve()
    const script = document.createElement('script')
    script.src = src
    script.onload = () => resolve()
    script.onerror = (err) => reject(err)
    document.body.appendChild(script)
  })

let client

const GoogleAuth = ({ clientId, onSuccess, userSet }) => {
  const googleButton = useRef(null)
  const [user, setUser] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client'
    const id = clientId

    loadScript(src)
      .then(() => {
        /* global google */
        google.accounts.id.initialize({
          client_id: id,
          callback: handleCredentialResponse,
          context: 'Hey, here is the text that we can change'

        })
        google.accounts.id.renderButton(
          googleButton.current,
          { theme: 'outline', size: 'large' }
        )
        google.accounts.id.prompt((notification) => {
          if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
            // continue with another identity provider.
            console.log('isNotDisplayed || isSkippedMoment')
          }
        })

        client = google.accounts.oauth2.initTokenClient({
          client_id: clientId,
          scope: 'https://www.googleapis.com/auth/calendar.readonly',
          callback: (response) => {
            onSuccess(response)
          }
        })
      })
      .catch(console.error)

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`)
      if (scriptTag) document.body.removeChild(scriptTag)
    }
  }, [])

  function handleCredentialResponse (response) {
    setUser(true)
    userSet({})
  }

  return (
    <>
      {!loading &&
        <>
          {!user &&
            <div ref={googleButton}></div>
          }
          {user &&
            <Button onClick={() => {
              setLoading(true)
              client.requestAccessToken()
            }
            } text="Connect Google Calendar"></Button>
          }
        </>
      }
      {loading &&
        <Loader></Loader>
      }
    </>
  )
}

export default GoogleAuth
