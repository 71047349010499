import { createUserWithEmailAndPassword } from 'firebase/auth'
import { auth } from './firebase'
import { create, getOne, remove, subscribeAll, subscribe, update } from './db'

const collectionName = 'users'

const createAuthRecord = async (email, password) => new Promise((resolve) => {
  createUserWithEmailAndPassword(
    auth,
    email,
    password
  ).then(resolve)
})

const userCreate = async (data) => {
  await create(collectionName, data)
}

const userDelete = async (groupId) => {
  await remove(collectionName, groupId)
}

const userUpdate = async (groupId, data) => {
  await update(collectionName, groupId, data)
}

const usersSubscribeAll = (mutationCallback) => subscribeAll(collectionName, mutationCallback)
const usersSubscribeTherapists = (mutationCallback) => subscribe(collectionName, [['therapist', '==', true]], mutationCallback)

const userGet = async (email) => await getOne(collectionName, email)

export {
  createAuthRecord,
  userCreate,
  userDelete,
  userGet,
  userUpdate,
  usersSubscribeAll,
  usersSubscribeTherapists
}
