import React from 'react'
import GoogleCalendarLogin from './calendar/login'

const GooglePage = () => {
  return (<>
    <GoogleCalendarLogin patientId={'58834912'}></GoogleCalendarLogin>
  </>)
}

export default GooglePage
