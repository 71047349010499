import { subscribeOne, update } from '../db'

const collectionName = 'garmin-oauth/unsigned/tokens'

const garminOauthUnsignedTokensSubscribeOne = (id, mutationCallback) => subscribeOne(collectionName, id, mutationCallback)

const garminOauthUnsignedTokensUpdate = (id, data) => {
  return update(collectionName, id, {
    ...data,
    updatedAt: new Date()
  })
}

export {
  garminOauthUnsignedTokensSubscribeOne,
  garminOauthUnsignedTokensUpdate
}
