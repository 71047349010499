const addDays = (date, days) => {
  return new Date(date.setDate(date.getDate() + days))
}

const addHours = (date, hours) => {
  return new Date((new Date(date)).getTime() + (hours * 60 * 60 * 1000))
}

const yesterday = () => {
  return addDays(new Date(), -1)
}

const toTimestamp = (time) => {
  return Math.round((new Date(time)).getTime() / 1000)
}

module.exports = {
  addDays,
  addHours,
  toTimestamp,
  yesterday
}
