import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: null
}

export const user = createSlice({
  name: 'stressInsights',
  initialState,
  reducers: {
    setUser: (state, action) => ({
      ...state,
      user: action.payload
    })
  }
})

export const { setUser } = user.actions

export default user.reducer
