/* eslint-disable */
import React from 'react'
import { googleOauthSaveTokens } from '../../../api/functions'
import Gsi from './gsi'
// import { getCalendarLists } from './api'
const GOOGLE_CLIENT_ID = '984159862747-t57oog9d4orm41i1ehr1eh1qhpkp303g.apps.googleusercontent.com'
// secret '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'

const calendarScope = 'profile email'

const GoogleCalendarLogin = ({ patientId, onSuccess, userSet }) => {
  const calendarTokensReceived = async (tokens) => {
    await googleOauthSaveTokens({
      patientId,
      scope: 'calendar',
      access_token: tokens.access_token,
      expires_in: tokens.expires_in
    })

    onSuccess()
  }

  return (
  <>
    <Gsi
      className="gsi"
      clientId={GOOGLE_CLIENT_ID}
      onSuccess={calendarTokensReceived} 
      userSet={userSet}
    ></Gsi>
  </>)
}

export default GoogleCalendarLogin
