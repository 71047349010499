import React, { useEffect, useState, useRef } from 'react'

const createPopup = ({
  url, title, height, width
}) => {
  const left = window.screenX + (window.outerWidth - width) / 2
  const top = window.screenY + (window.outerHeight - height) / 2.5
  const externalPopup = window.open(
    url,
    title,
    `width=${width},height=${height},left=${left},top=${top}`
  )
  return externalPopup
}

const OauthPopup = ({
  title = '',
  width = 500,
  height = 500,
  url,
  children,
  onCode,
  onClose
}) => {
  const [externalWindow, setExternalWindow] = useState()
  const intervalRef = useRef()
  const clearTimer = () => {
    window.clearInterval(intervalRef.current)
  }

  const onContainerClick = () => {
    setExternalWindow(createPopup({
      url, title, width, height
    }))
  }

  useEffect(() => {
    if (externalWindow) {
      intervalRef.current = window.setInterval(() => {
        try {
          const currentUrl = externalWindow.location.href
          const params = new URL(currentUrl).searchParams
          const code = params.get('code')
          if (!code) {
            return
          }
          onCode(code, params)
          clearTimer()
          externalWindow.close()
        } catch (error) {
          // eslint-ignore-line
        } finally {
          if (!externalWindow || externalWindow.closed) {
            onClose()
            clearTimer()
          }
        }
      }, 500)
    }
    return () => {
      /*
      if (externalWindow) {
        console.log('externalWindow.close()')
        console.log(externalWindow.location.href)
        const currentUrl = externalWindow.location.href
        const params = new URL(currentUrl).searchParams
        console.log(params.get('code'))

        externalWindow.close()
      }
      */

      if (onClose) {
        onClose('no external window')
      }
    }
  }, [externalWindow])

  return (
    // eslint-disable-next-line
    <div
      onClick={() => {
        onContainerClick()
      }}
    >
      { children }
    </div>
  )
}

export default OauthPopup
