import React, { useEffect, useState } from 'react'
import OauthPopup from 'react-oauth-popup'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Button } from 'reactstrap'
import {
  garminOauthRequestToken,
  garminOauthAccessToken,
  garminPatientHasReceivedData
} from '../../../api/functions'
import { garminOauthUnsignedTokensSubscribeOne, garminOauthUnsignedTokensUpdate } from '../../../api/garmin-connect/oauth'
import { getMixpanel } from '../../../api/mixpanel'
import Loader from '../../../components/Loader'
import StepCounter from '../../../components/step-counter'
import { yesterday } from '../../../utils/date'
import '../../LoginPage/index.scss'

const mixpanel = getMixpanel()

const GarminConnect = () => {
  const navigate = useNavigate()

  const [completed, setCompleted] = useState(false)
  const [signed, setSigned] = useState(false)
  const [oauthToken, setOauthToken] = useState(false)
  const [requestData, setRequestData] = useState(false)
  const patientId = useSelector(state => state.stressInsights.user)

  const onCode = () => {
  }

  const watchForData = () => {
    const intervalId = setInterval(async () => {
      const { data } = await garminPatientHasReceivedData({ patientId })
      const { garminReceivedData } = data

      if (garminReceivedData) {
        clearInterval(intervalId)
        moveForward()
      }
    }, 10000)
  }

  const moveForward = () => {
    navigate('/' + (new Date(yesterday()).toISOString().substring(0, 10)))
  }

  useEffect(() => {
    const run = async () => {
      const { data } = await garminOauthRequestToken({ patientId, host: window.location.origin })

      if (data.hasGarminTokens) {
        moveForward()
      }

      setOauthToken(data.oauth_token)
      setRequestData({
        ...data
      })
    }

    if (!patientId) {
      navigate('/')
    }

    if (!oauthToken) {
      run()
    }
  }, [patientId])

  useEffect(() => {
    if (!oauthToken) {
      return false
    }

    const unsubscribe = garminOauthUnsignedTokensSubscribeOne(oauthToken, async (data) => {
      if (data.exists && !data.metadata.patientId) {
        garminOauthUnsignedTokensUpdate(oauthToken, { patientId })
        setSigned(true)

        await garminOauthAccessToken({
          patientId,
          oauth_nonce: requestData.oauth_nonce,
          oauth_timestamp: requestData.oauth_timestamp,
          oauth_token: oauthToken,
          oauth_verifier: data.metadata.verifier,
          oauth_token_secret: requestData.oauth_token_secret
        })

        setCompleted(true)

        const eventName = 'Connect with Fitness Tracker A02'

        mixpanel.track(eventName, {
          'Fitness Tracker Name': 'Garmin'
        })

        return watchForData()
      }
    })

    return unsubscribe
  }, [oauthToken, requestData])

  return (<>
    {!completed && !signed &&
      <>
        {!requestData &&
          <Loader></Loader>
        }
        {requestData && (
          <OauthPopup
            url={requestData.url}
            onCode={onCode}
            onClose={() => {}}
          >
            <div className="garmin-connect">
              <Button className="button">Connect with Garmin</Button>
            </div>
          </OauthPopup>)
        }
      </>
    }
    {signed &&
      <>
        <Loader></Loader>
      </>
    }
      <StepCounter
        steps={2}
        step={2}
      ></StepCounter>
  </>)
}

export default GarminConnect
