import React from 'react'
import { btnStyles } from '../../utils/constants'
import './index.scss'

const Button = ({
  type = 'button',
  text = 'Button',
  onClick = () => {},
  disabled = false,
  btnStyle = btnStyles.default,
  style = {}
}) => (
  <button
    type={type}
    className={`button button_type_${btnStyle} ${disabled ? 'button_type_disabled' : ''}`}
    onClick={onClick}
    disabled={disabled}
    style={style}
  >
    {text}
  </button>
)

export default Button
